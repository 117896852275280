module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://resicentral.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ResiCentral, LLC Mortgage","short_name":"test","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/Resi_favicon_blue.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"503ef91458f799a0fca873d316542ba6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/fonts/*":["Cache-Control: public, max-age=31536000, immutable"]}},
    },{
      plugin: require('../node_modules/@bond-london/gatsby-theme/gatsby-browser.ts'),
      options: {"plugins":[],"showDevPages":false,"isProduction":true,"projectName":"ResiCentral, LLC Mortgage","useVideoCache":true,"videoCacheConnectionString":"DefaultEndpointsProtocol=https;AccountName=bondcache;AccountKey=V3vM2K9hSp1CWuueICom8jB2i5TiC6tisJLZUd6TCIaKMfXm5MR+rebcVLlgDUComIVTBp8ZXi1t+ASt7Uw9Sw==;EndpointSuffix=core.windows.net","videoWidth":1920,"graphCMSToken":"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2Mzg5NzM0NDIsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEuZ3JhcGhjbXMuY29tL3YyL2NrdXk5bnc5eTA2eG4wMXdjMmlmdmRwbmUvbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMjlkMTNhODMtMWFhNS00ZWEwLTk0ZmItNzVhNWU4M2ZhZjFkIiwianRpIjoiY2t3eG1neTVkMDQzdDAxejRkYTFrOGp6aCJ9.c7MOBDlvBEzy0iCuSv1ChR9GrJ25ucqkSxON4MfpibWM6rfQfBoONscHjEXIbTwDJ9QFn5hOWYWhXRdQBWL-c5eM1ytIDuHKsaPJiGojHZ5Uen6JpnCHencqDluAscgY6cLOKecTDZvx8SrQCZ1f1EW2IDeKwkP-GtvM2G4PN3kvzbIUEHcd0PSm3yV919EfQjJggAntUaunJA6iynCUP4ZC15fb5iqP0jd5CcLGWFSi5sVt4uOkQK6CuxAD7K2QccUIkrDmKn9QslhvaBy9O60DwxhwYmhQ03QltB3jvSW0lvG1Y-IAVWkaGHHbL_1l4hbgutnBXrvNXmzXLo2ZGpEA-bCTjeqnQuNl-lMvhcuntcnthfpgGOMTCpKsFbm6-jrhwv33z3VCOV20jGI8B0ctJFrCwCZwjttq76DBFZUYukiVljcSdGqjxiwyK_ibGalN0ubgEeFKIywof_CvoC2rDHI3DSxHwizvqL7NmOg6rN62QuBrRiC6RBjuQiw_YAAGSjCsj4aSKe25KZNeCavVyj1PbIARwcUU6KnPW0a4956yIHt1fPikjxm0T4Ry4UVnZGp5gDY-gHxetR8MSM1y_4OBIxLWSrLHZQzVOeaFl6I_C1U-O4XOwzjsN-AvNPkj-QI7lAlgGahrbPEMUMPGaO0upSF3lvPEbxVSMBI","graphCMSEndpoint":"https://api-eu-central-1.graphcms.com/v2/ckuy9nw9y06xn01wc2ifvdpne/master","graphCMSStage":"PUBLISHED","enableEslint":false,"siteUrl":"https://resicentral.com","icon":"src/images/Resi_favicon_blue.png","allowIndex":true,"manifestOptions":{"name":"ResiCentral, LLC Mortgage","short_name":"test","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/Resi_favicon_blue.png"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KWPT5LN","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
