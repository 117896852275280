import { RouteUpdateArgs } from "gatsby";
import "./src/styles/index.css";
import "@fontsource/poppins/300.css";

console.log("%cDesigned by BOND", "font-weight: bold");

declare global {
  interface Window {
    bondPreviousLocation: Location | null | undefined;
  }
}

export function onRouteUpdate(args: RouteUpdateArgs) {
  console.log("on route update", args);
  window.bondPreviousLocation = args.prevLocation;
}
